// used to auto resizing etc.
export const AUTO = -1;

// Align modes for cover, contain, bit masks
export const HORIZONTAL_ALIGN_LEFT = 1;
export const HORIZONTAL_ALIGN_CENTER = 2;
export const HORIZONTAL_ALIGN_RIGHT = 4;

export const VERTICAL_ALIGN_TOP = 8;
export const VERTICAL_ALIGN_MIDDLE = 16;
export const VERTICAL_ALIGN_BOTTOM = 32;

// blend modes
export const BLEND_SOURCE_OVER = "srcOver";
export const BLEND_DESTINATION_OVER = "dstOver";
export const BLEND_MULTIPLY = "multiply";
export const BLEND_ADD = "add";
export const BLEND_SCREEN = "screen";
export const BLEND_OVERLAY = "overlay";
export const BLEND_DARKEN = "darken";
export const BLEND_LIGHTEN = "lighten";
export const BLEND_HARDLIGHT = "hardLight";
export const BLEND_DIFFERENCE = "difference";
export const BLEND_EXCLUSION = "exclusion";

// Edge Handling
export const EDGE_EXTEND = 1;
export const EDGE_WRAP = 2;
export const EDGE_CROP = 3;
